import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"

import styles from "./blog-list-template.module.scss"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? "/blog" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <SEO title="Blog" />
        <TitleText text="Blog" />
        <div className={styles.blogPosts}>
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <div className={styles.blogPostPreview} key={post.id}>
                  <Link to={post.frontmatter.path}>
                    <h4> {post.frontmatter.title}</h4>
                    <p>{post.excerpt}</p>
                    <br></br>
                    <p>Ir al blog</p>
                  </Link>
                </div>
              )
            })}
          <div className={styles.pagination}>
            {!isFirst && (
              <Link className={styles.prevPage} to={prevPage} rel="prev">
                ← Página anterior
              </Link>
            )}
            {!isLast && (
              <Link className={styles.nextPage} to={nextPage} rel="next">
                Siguiente página →
              </Link>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { fileAbsolutePath: { regex: "/(blog-posts)/.*.md$/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
